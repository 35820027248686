@if (form) {
    <form
        *transloco="let t; prefix: translationScope"
        [formGroup]="form"
        (ngSubmit)="submit($event)">
        <h4>{{ t('label.title') }}</h4>
        <div class="grid md:grid-cols-12 md:gap-x-6">
            <big-ui-form-row
                #postcodeOrCity
                class="col-span-6"
                [id]="'postcodeOrCity'"
                [label]="t('label.postcodeOrCity')"
                [isRequired]="required"
                [showError]="hasBeenSubmitted"
                [errorOverrides]="autocompleteErrorOverrides"
                [errors]="form.controls.postcodeOrCity.errors">
                <big-ui-autocomplete-input
                    [id]="'postcodeOrCity'"
                    formControlName="postcodeOrCity"
                    name="postcodeOrCity"
                    [isValid]="hasBeenSubmitted && form.controls.postcodeOrCity.valid"
                    [isInvalid]="hasBeenSubmitted && !form.controls.postcodeOrCity.valid"
                    [isRequired]="required"
                    [optionsValueTransformer]="transformOptionValue"
                    [options]="locationOptions()"
                    [requireSelection]="true"
                    (inputChanges)="onAutocompleteInputChange($event)"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: postcodeOrCity.label!,
                        valid: !hasBeenSubmitted || form.controls.postcodeOrCity.valid,
                    }" />
            </big-ui-form-row>
            <big-ui-form-row
                #searchRange
                class="col-span-6 mb-6"
                [id]="'searchRange'"
                [label]="t('label.searchRange')"
                [isRequired]="true"
                [errors]="form.controls.searchRange.errors"
                [showError]="hasBeenSubmitted">
                <big-ui-select
                    [id]="'searchRange'"
                    [isValid]="form.controls.searchRange.valid"
                    [options]="searchRangeOptions"
                    [showNoSelectionOption]="false"
                    [showValidation]="hasBeenSubmitted"
                    formControlName="searchRange"
                    name="searchRange"
                    [bigStFieldTracking]="{
                        metadata,
                        fieldName: searchRange.label!,
                        valid: !hasBeenSubmitted || form.controls.searchRange.valid,
                    }" />
            </big-ui-form-row>
        </div>
        @if (medicalFields()) {
            <ng-container [formGroupName]="'medicalInformation'">
                <big-ui-form-row
                    #medicalField
                    [id]="'medicalField'"
                    [label]="t('label.medicalField')"
                    [isRequired]="false"
                    [errors]="form.controls.medicalInformation.controls.medicalField.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-select
                        [id]="'medicalField'"
                        [isValid]="form.controls.medicalInformation.controls.medicalField.valid"
                        [disableTransloco]="true"
                        [options]="medicalFields()"
                        [showValidation]="hasBeenSubmitted"
                        formControlName="medicalField"
                        name="medicalField"
                        (ngModelChange)="onMedicalFieldChange($event)"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: medicalField.label!,
                            valid: !hasBeenSubmitted || form.controls.medicalInformation.controls.medicalField.valid,
                        }" />
                </big-ui-form-row>
                <big-ui-form-row
                    #technicalTerm
                    [id]="'technicalTerm'"
                    [label]="t('label.technicalTerm')"
                    [isRequired]="false"
                    [isDisabled]="form.controls.medicalInformation.controls.technicalTerm.disabled"
                    [errors]="form.controls.medicalInformation.controls.technicalTerm.errors"
                    [showError]="hasBeenSubmitted">
                    <big-ui-select
                        [id]="'technicalTerm'"
                        [isValid]="form.controls.medicalInformation.controls.technicalTerm.valid"
                        [disableTransloco]="true"
                        [options]="technicalTerms()"
                        [showValidation]="hasBeenSubmitted"
                        formControlName="technicalTerm"
                        name="technicalTerm"
                        [bigStFieldTracking]="{
                            metadata,
                            fieldName: technicalTerm.label!,
                            valid: !hasBeenSubmitted || form.controls.medicalInformation.controls.technicalTerm.valid,
                        }" />
                </big-ui-form-row>
            </ng-container>
        }
        <big-ui-form-row
            #homeopathName
            [id]="'homeopathName'"
            [label]="t('label.homeopathName')"
            [isRequired]="false"
            [showError]="hasBeenSubmitted"
            [errors]="form.controls.homeopathName.errors">
            <big-ui-input
                [id]="'homeopathName'"
                formControlName="homeopathName"
                [isValid]="form.controls.homeopathName.valid"
                [showValidation]="hasBeenSubmitted"
                [bigStFieldTracking]="{
                    metadata,
                    fieldName: homeopathName.label!,
                    valid: !hasBeenSubmitted || form.controls.homeopathName.valid,
                }" />
        </big-ui-form-row>
        <big-ui-button
            [buttonStyle]="'secondary'"
            class="block my-10 last:mb-0"
            (clicked)="submit($event)">
            {{ t('label.submit') }}
        </big-ui-button>
        @if (form.valid && showResults) {
            <h4 id="resultsHeader">
                {{ t('label.resultCount', { hits: resultCount() }) }}
            </h4>
            @if (resultCount() > 0) {
                <div class="grid gap-6 md:grid-cols-12">
                    @for (data of cardData(); track $index) {
                        <big-ui-card-headline-text-link
                            [data]="data"
                            class="col-span-6"></big-ui-card-headline-text-link>
                    }
                </div>
                @if (resultCount() > elementsPerPage) {
                    <big-ui-pagination
                        [currentPage]="currentPageCount | async"
                        [totalItems]="resultCount()"
                        [pageSize]="elementsPerPage"
                        (pageChange)="onPageChange($event)"
                        class="mt-6" />
                }
            }
        }
    </form>
}
